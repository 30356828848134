@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Outfit;
  font-style: normal;
  font-weight: 100;
  src: url("../public/assets/fonts/Outfit/Outfit-Thin.ttf") format("truetype");
}
@font-face {
  font-family: Outfit;
  font-style: normal;
  font-weight: 200;
  src: url("../public/assets/fonts/Outfit/Outfit-ExtraLight.ttf")
    format("truetype");
}
@font-face {
  font-family: Outfit;
  font-style: normal;
  font-weight: 300;
  src: url("../public/assets/fonts/Outfit/Outfit-Light.ttf") format("truetype");
}
@font-face {
  font-family: Outfit;
  font-style: normal;
  font-weight: 400;
  src: url("../public/assets/fonts/Outfit/Outfit-Regular.ttf")
    format("truetype");
}
@font-face {
  font-family: Outfit;
  font-style: normal;
  font-weight: 500;
  src: url("../public/assets/fonts/Outfit/Outfit-Medium.ttf") format("truetype");
}
@font-face {
  font-family: Outfit;
  font-style: normal;
  font-weight: 600;
  src: url("../public/assets/fonts/Outfit/Outfit-SemiBold.ttf")
    format("truetype");
}
@font-face {
  font-family: Outfit;
  font-style: normal;
  font-weight: 700;
  src: url("../public/assets/fonts/Outfit/Outfit-Bold.ttf") format("truetype");
}
@font-face {
  font-family: Outfit;
  font-style: normal;
  font-weight: 800;
  src: url("../public/assets/fonts/Outfit/Outfit-ExtraBold.ttf")
    format("truetype");
}
@font-face {
  font-family: Outfit;
  font-style: normal;
  font-weight: 900;
  src: url("../public/assets/fonts/Outfit/Outfit-Black.ttf") format("truetype");
}

@layer base {
  h2 {
    @apply text-white md:text-6xl leading-10 text-4xl md:leading-[90px] md:font-normal font-medium font-sans text-center;
  }

  h4 {
    @apply lg:text-3xl text-xl font-bold lg:leading-[40px];
  }
}

@layer components {
  .homepage-swiper2 .swiper-pagination-bullet-active {
    @apply !bg-whiteSmoke !w-32;
  }

  .homepage-swiper1 .swiper-wrapper,
  .about-swiper1 .swiper-wrapper {
    transition-timing-function: linear;
  }

  .homepage-swiper1 .swiper-slide,
  .about-swiper1 .swiper-slide {
    pointer-events: none;
    -webkit-transform: translate3d(0, 0, 0);
  }

  @screen lg {
    .nav-item::before {
      content: "";
      @apply block absolute left-0 -bottom-0.5 w-0 h-0.5 bg-primary transition-all ease-out duration-300;
    }

    .nav-item.active:before {
      @apply w-full;
    }
  }

  .text-animate {
    animation: 1s fadeIn;
    animation-fill-mode: forwards;
    transition: all 0.35s ease;
  }
  .bg-animation {
    animation: float 6s ease-in-out infinite;
    overflow: hidden;
    transform: translateY(0px);
  }
  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-50px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  section {
    @apply overflow-hidden;
  }

  .circle-shape-career {
    opacity: 0.7;
  }

  @keyframes fadeIn {
    0% {
      @apply text-black;
    }
    100% {
      @apply text-primary;
    }
  }
}

.hero-slider .swiper-slide .animated-text {
  position: relative;
  bottom: 190px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

.hero-slider .swiper-slide-active .animated-text {
  bottom: 0;
  opacity: 1;
}
.object-center-top {
  object-position: center;
}

@media (max-width: 420px) {
  .object-center-top {
    object-position: 0 -60px;
  }
}
